<template>
  <v-form>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-container>
            <v-flex>
              <v-btn
                class="mr-4"
                color="primary"
                @click="submit(true)"
                :disabled="isSaveDisabled || isDisabled"
                :loading="isSaveDisabled"
              >
                Сохранить
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                @click="submit(false)"
                :disabled="isSaveDisabled || isDisabled"
                :loading="isSaveDisabled"
              >
                Сохранить и продолжить
              </v-btn>
              <div class="float-right">
                <v-btn
                  v-if="form.id"
                  color="green"
                  class="input-style"
                  @click="downloadTechTask"
                  :disabled="isDownloadDisabled"
                >
                  Скачать ТЗ
                </v-btn>
              </div>
              <input
                v-if="form.id"
                ref="file"
                type="file"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                class="input-file"
                v-on:change="uploadTechTask"
                :disabled="isSaveDisabled || isDisabled"
              >
              <v-btn
                v-if="form.id"
                color="green"
                @click="$refs.file.click()"
                class="float-right input-style mr-4"
                :title="`${uploadButtonText}`"
              >
                <span class="text-overflow">
                  {{ uploadButtonText }}
                </span>
              </v-btn>
            </v-flex>
            <v-text-field
              v-if="fieldCheck('title', entity)"
              v-model="form.title"
              :error-messages="errors.title"
              label="Название"
              name="title"
              required
            ></v-text-field>
            <v-text-field
              v-if="fieldCheck('slug', entity)"
              v-model="form.slug"
              :error-messages="errors.slug"
              label="Slug"
              name="slug"
              required
              :disabled="!(Object.keys(article).length === 0)"
            ></v-text-field>
            <a
              v-if="article.preview_url"
              :href="article.preview_url"
              target="_blank"
              class="mr-3"
            >
              {{ article.preview_url }}
            </a>
            <preview v-if="article.preview_url" :previewUrl="article.preview_url"/>
            <v-textarea
              v-if="fieldCheck('lead', entity)"
              v-model="form.lead"
              :error-messages="errors.type"
              label="Лид"
              name="lead"
              required
            ></v-textarea>
            <media-widget
              v-if="fieldCheck('preview_image', entity)"
              class="mb-1"
              label="Анонсное изображение"
              :medias="form.preview_image"
              :is-multiple="false"
              @onChange="setPreviewImage"
            />
            <v-checkbox
              v-model="form.wide_header"
              label="Широкая шапка"
            />
            <label>Контент</label>
            <media-form
              :key="reRenderMedia"
              ref="media-form"
              :structure="currentMedia.structure"
              :is-show-save="false"
              style="margin: 0 auto"
              :entityId="$route.params.id"
              :entityClass="`Modules\\\\Articles\\\\Entities\\\\Article`"
            />
          </v-container>
        </v-card>
      </v-col>
      <v-col class="col-4">
        <v-card>
          <v-container>
            <v-datetime-picker
              v-if="fieldCheck('published_at', entity)"
              v-model="form.published_at"
              scrollable
              label="Дата публикации. По умолчанию текущая дата и время"
              clear-text="Очистить"
            ></v-datetime-picker>

            <v-select
              v-if="regions && fieldCheck('region_id', entity)"
              v-model="form.region_id"
              :error-messages="errors.region_id"
              :items="regions"
              item-text="title"
              item-value="id"
              label="Выберите регион"
              name="region_id"
              required
            ></v-select>
            <v-row align="center">
              <v-col class="col-auto pr-0">
                <v-btn
                  icon
                  color="green"
                  @click.stop="sourcesDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="pl-0">
                <v-autocomplete
                  v-if="sources && fieldCheck('source_id', entity)"
                  v-model="form.source_id"
                  :error-messages="errors.source_id"
                  :items="sources"
                  item-text="title"
                  item-value="id"
                  label="Выберите источник"
                  name="research_id"
                  required
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-autocomplete
              v-if="persons && fieldCheck('selected_authors', entity)"
              v-model="form.selected_authors"
              :error-messages="errors.selected_authors"
              :items="persons"
              item-text="full_name"
              item-value="id"
              label="Выберите авторов"
              name="selected_authors"
              multiple
              required
            ></v-autocomplete>
            <v-select
              v-if="statuses && fieldCheck('status', entity)"
              v-model="form.status"
              :error-messages="errors.status"
              :items="statuses"
              label="Статус"
              name="status"
              required
            ></v-select>
            <v-select
              v-if="formats && fieldCheck('format', entity)"
              v-model="form.format"
              :error-messages="errors.format"
              :items="formats"
              label="Формат"
              name="format"
              required
            ></v-select>
            <v-select
              v-if="types && fieldCheck('type', entity)"
              v-model="form.type"
              :error-messages="errors.type"
              :items="filtrableTypeList"
              label="Тип"
              name="type"
              required
            ></v-select>
            <v-checkbox
              v-if="fieldCheck('is_fix_on_main', entity)"
              v-model="form.is_fix_on_main"
              :error-messages="errors.is_fix_on_main"
              label="Открепить анонс из блока актуальных тем"
              name="is_fix_on_main"
              required
            ></v-checkbox>
            <v-checkbox
              v-if="fieldCheck('is_archive', entity)"
              v-model="form.is_archive"
              :error-messages="errors.is_archive"
              label="Архивная запись"
              name="is_archive"
              required
            ></v-checkbox>
            <v-text-field
              v-if="fieldCheck('old_id', entity)"
              v-model="form.old_id"
              :error-messages="errors.old_id"
              label="Старый id"
              name="old_id"
              required
            ></v-text-field>
            <template v-if="form.type === 14">
              <v-text-field type="number" label="Введите стоимость" v-model="form.amount"/>
              <v-checkbox
                v-model="form.date_is_not_confirmed"
                label="Точная дата уточняется"
              />
              <v-text-field
                v-model="form.location"
                label="Адрес проведения"
              />
              <v-text-field
                v-model="form.notification_email"
                label="Email для уведомления"
              />
              <div>
                <v-row>
                  <v-col>
                    <v-datetime-picker
                      scrollable
                      :time-picker-props="timeProps"
                      v-model="form.event_date_start"
                      label="Дата начала"
                      clear-text="Очистить"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-time-eight-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                  <v-col>
                    <v-datetime-picker
                      scrollable
                      :time-picker-props="timeProps"
                      v-model="form.event_date_end"
                      label="Дата конца"
                      clear-text="Очистить"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-time-eight-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      :items="partnerList"
                      v-model="form.partner_id"
                      item-value="value"
                      item-text="label"
                      label="Выберите партнера"
                    />
                  </v-col>
                </v-row>
                <v-card>
                  <v-card-title>
                    Дополнительные даты
                  </v-card-title>
                  <v-card-text class="px-0">
                    <v-container v-for="(article_event_date, index) in form.event_dates" :key="index" :class="{'grey lighten-5': index % 2}">
                      <v-row>
                        <v-col>
                          <v-datetime-picker
                            scrollable
                            :time-picker-props="timeProps"
                            v-model="article_event_date.start_date"
                            label="Дата начала"
                            clear-text="Очистить"
                            :disabled="true"
                          >
                            <template slot="dateIcon">
                              <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>mdi-clock-time-eight-outline</v-icon>
                            </template>
                          </v-datetime-picker>
                        </v-col>
                        <v-col>
                          <v-datetime-picker
                            scrollable
                            :time-picker-props="timeProps"
                            v-model="article_event_date.end_date"
                            label="Дата конца"
                            clear-text="Очистить"
                            :disabled="true"
                          >
                            <template slot="dateIcon">
                              <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>mdi-clock-time-eight-outline</v-icon>
                            </template>
                          </v-datetime-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col class="py-0">
                          <v-autocomplete :disabled="true" :items="partnerList" v-model="article_event_date.partner_id" item-value="value" item-text="label" label="Выберите партнера"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-btn block color="primary" @click="dateDialog = true">Редактировать даты</v-btn>
              </div>
            </template>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit(true)"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить
          </v-btn>
          <v-btn
            class="mr-4"
            color="primary"
            @click="submit(false)"
            :disabled="isSaveDisabled || isDisabled"
            :loading="isSaveDisabled"
          >
            Сохранить и продолжить
          </v-btn>
        </v-flex>
      </v-col>
    </v-row>
    <media-uploader
      :loaded-medias="article.loaded_medias"
      @completeUploads="onCompleteUploads"
    />
    <v-dialog
      v-model="sourcesDialog"
      max-width="600px"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <h1>Создание источника</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <source-form
                :errors="formErrors"
                @onSubmit="createSource"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dateDialog" persistent max-width="800">
      <v-card>
        <v-card-title>
          Добавление даты
          <v-btn icon @click="addDate">
            <v-icon color="green">mdi-plus</v-icon>
          </v-btn>
          <v-card-actions>
            <v-btn block color="primary" class="ml-3 mr-3" @click="saveDate" :disabled="!dates_is_valid">
              Сохранить
            </v-btn>
          </v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="dateDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container ref="test">
            <template v-for="(event_date, index) in event_dates">
              <ValidationObserver :ref="`observer_${index}`" :key="index" v-slot="{ invalid }">
                <v-row>
                  <v-col cols="3">
                    <validation-provider :name="`start_date_${index}`" rules="required">
                      <v-datetime-picker
                        :name="`start_date_${index}`"
                        :time-picker-props="timeProps"
                        v-model="event_date.start_date"
                        label="Дата начала"
                        clear-text="Очистить"
                      ></v-datetime-picker>
                    </validation-provider>
                  </v-col>
                  <v-col cols="3">
                    <validation-provider :name="`end_date_${index}`" rules="required">
                      <v-datetime-picker
                        :name="`end_date_${index}`"
                        :time-picker-props="timeProps"
                        v-model="event_date.end_date"
                        label="Дата конца"
                        clear-text="Очистить"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-clock-time-eight-outline</v-icon>
                        </template>
                      </v-datetime-picker>
                    </validation-provider>
                  </v-col>
                  <v-col cols="4">
                    <validation-provider :name="`partner_id_${index}`" rules="required">
                      <v-autocomplete
                        :name="`partner_id_${index}`"
                        :items="partnerList"
                        v-model="event_date.partner_id"
                        item-value="value"
                        item-text="label"
                        label="Выберите партнера"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col>
                    <v-btn icon @click="deleteDate(index)" class="mt-3">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                    <v-icon :color="invalid ? 'red' : 'green'" class="mt-3">
                      {{ invalid ? 'mdi-close' : 'mdi-check' }}
                    </v-icon>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import MediaForm from '../medias/MediaForm';
import slugify from 'slugify';
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import {Region} from "../../models/region";
import moment from 'moment';
import {Source} from "../../models/source";
import SourceForm from '../sources/SourceForm';
import {Person} from "../../models/person";
import optionsMixin from "../../mixins/optionsMixin";
import { mapGetters, mapActions } from "vuex";
import axiosInstance from '../../axiosInstance';

export default {
  name: 'ArticleForm',
  mixins: [optionsMixin],
  components: {
    MediaWidget,
    MediaForm,
    MediaUploader,
    SourceForm,
  },
  props: {
    article: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: null,
      article_id: null,
      region_id: null,
      research_id: null,
      title: null,
      slug: null,
      language: null,
      preview_image: null,
      published_at: null,
      status: null,
      selected_authors: null,
      type: null,
      format: null,
      lead: null,
      is_fix_on_main: false,
      is_archive: false,
      old_id: null,
      loading: false,
      event_date_start: null,
      event_date_end: null,
      date_is_not_confirmed: false,
      location: null,
      notification_email: null,
      amount: 0,
      partner_id: null,
      event_dates: [],
    },
    dates_is_valid: true,
    dateDialog: false,
    event_dates: [],
    formErrors: {},
    currentMedia: {
      id: null,
      entity: 'Modules\\Articles\\Entities\\Article',
      entity_id: null,
      structure: null,
    },
    languages: [
      {text: 'Русский', value: 'ru'},
      {text: 'English', value: 'en'},
    ],
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Заполанировано', value: 1},
      {text: 'Опубликовано', value: 2},
    ],
    types: [
      {text: 'Интервью', value: 2, settingKey: 'TYPE_INTERVIEW'},
      {text: 'Личная история', value: 3, settingKey: 'TYPE_PERSONAL_HISTORY'},
      {text: 'Новость', value: 4, settingKey: 'TYPE_NEWS'},
      {text: 'Инструкция', value: 5, settingKey: 'TYPE_INSTRUCTIONS'},
      {text: 'Экспертное мнение', value: 6, settingKey: 'TYPE_EXPERT_OPINION'},
      {text: 'Вопрос&ответ', value: 9, settingKey: 'TYPE_QUESTION_ANSWER'},
      {text: 'Дайджест', value: 10, settingKey: 'TYPE_DIGEST'},
      {text: 'Подкаст', value: 13, settingKey: 'TYPE_PODCAST' },
    ],
    formats: [
      {text: 'Читать', value: 1},
      {text: 'Слушать', value: 2},
      {text: 'Смотреть', value: 0},
      {text: 'Участвовать', value: 3},
      {text: 'Применить', value: 4},
    ],
    timeProps: {
      format: '24hr',
      useSeconds: false,
    },
    event_date: {
      start_date: null,
      end_date: null,
      partner_id: null,
    },
    observerRefs: [],
    reRenderMedia: 1,
    regions: [],
    sources: [],
    persons: [],
    mediaIds: [],
    sourcesDialog: false,
    isSaveDisabled: false,
    entity: 'Modules\\\\Articles\\\\Entities\\\\Article',
    partnerList: [],
    uploadedTechTask: null,
  }),
  async created() {
    this.loading = true;
    if (this.$route.params.media) {
      this.currentMedia = JSON.parse(this.$route.params.media);
    }
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.getRegions();
    this.getSources();
    this.getPersons();
    this.partnerList = await this.fetchEntityList({
      field_value: "id",
      field_label: "title",
      entity_class: "App\\Models\\Partner",
    });

    if (Object.keys(this.currentSite).length > 0) {
      this.fetchEntityOptions(this.entity);
    }
  },
  mounted() {
    this.form.language = this.language;
  },
  computed: {
    ...mapGetters({
      'fieldCheck': 'EntityOptions/checkOption',
      'currentSite': 'SiteSwitcher/getSelectedSite'
    }),
    computedCurrentSite() {
      return this.currentSite;
    },
    filtrableTypeList() {
      return this.types.filter((item) => {
        return this.fieldCheck(item.settingKey, this.entity);
      })
    },
    isDownloadDisabled() {
      return !(this.uploadedTechTask || this.article.tech_task_file);
    },
    uploadButtonText() {
      return this.uploadedTechTask != null
        ? this.uploadedTechTask
        : this.article.tech_task_file != null
          ? this.article.tech_task_file
          : 'Загрузить ТЗ';
    },
  },
  methods: {
    ...mapActions({
      fetchEntityOptions: "EntityOptions/fetchOptions",
      fetchEntityList: "Constants/fetchEntityList",
    }),
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit();
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      const data = await this.$refs['media-form'].submit();

      if (data) {
        this.currentMedia.structure = data;
      }

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;

      this.form.event_date_start = this.form.event_date_start
        ? moment(this.form.event_date_start).format('YYYY-MM-DD HH:mm')
        : null;

      this.form.event_date_end = this.form.event_date_end
        ? moment(this.form.event_date_end).format('YYYY-MM-DD HH:mm')
        : null;

      if (this.form.status === null) {
        this.form.status = 0;
      }

      this.$emit('onSubmit', {
        form: this.form,
        media: this.currentMedia,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect,
      });
    },
    setPreviewImage(image) {
      this.form.preview_image = image;
    },
    async createSource(form) {
      try {
        this.errors = {};
        await Source.$query().store(form);
        this.sourcesDialog = false;
        await this.getSources();
      } catch (e) {
        if (e.response?.data) {
          this.formErrors = e.response.data.errors
        }
        throw e;
      }
    },
    async getRegions() {
      try {
        const regions = await Region.$query().get(100);
        this.regions = regions.map(region => region.$attributes);
      } catch (e) {
        // throw e;
      }
    },
    async getSources() {
      try {
        const sources = await Source.$query().get(100);
        this.sources = sources.map(source => source.$attributes);
      } catch (e) {
        throw e;
      }
    },
    async getPersons() {
      try {
        const persons = await Person.$query().get(500);
        this.persons = persons.map(person => person.$attributes);
        this.loading = false;
      } catch (e) {
        throw e;
      }
    },
    addDate() {
      this.event_dates.push(JSON.parse(JSON.stringify(this.event_date)));
    },
    deleteDate(index) {
      this.event_dates.splice(index, 1);
    },
    saveDate() {
      this.form.event_dates = JSON.parse(JSON.stringify(this.event_dates));
      this.submit(false);
    },
    uploadTechTask(e) {
      const supported_mimes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (supported_mimes.includes(e.target.files[0]?.type)) {
        console.log(`Try upload file with mime: ${e.target.files[0].type}`);

        const config = {headers:{'content-type':'multipart/form-data'}};

        let data = new FormData();
        data.append('techtask', e.target.files[0]);

        if (this.article?.id != null) {
          axiosInstance.post(
            `/module/articles/${this.article.id}/upload-techtask`,
            data,
            config
          ).then((res) => {
            console.log(`Uploaded: ${res.data}`);
            this.uploadedTechTask = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
        }
      } else {
        console.log('Refused file with mime: ' + e.target.files[0]?.type ?? 'unknown');
      }
    },
    async downloadTechTask() {
      await axiosInstance.get(
        `/module/articles/${this.article.id}/download-techtask`
      ).then(function (res) {
        window.open(res.data);
      }).catch(function (err) {
        console.log(err);
      });
    },
  },
  watch: {
    article: {
      handler(article) {
        if (article && Object.keys(article).length > 0) {
          this.form = article;
          this.form.language = this.language;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.currentMedia = JSON.parse(JSON.stringify(media));
          this.reRenderMedia += 1;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.article).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
    'form.type': {
      handler(value) {
        if (value !== 14) {
          this.form.event_date_start = null;
          this.form.event_date_end = null;
          this.form.notification_email = null;
          this.form.date_is_not_confirmed = false;
          this.form.location = null;
        }
      }
    },
    computedCurrentSite: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.fetchEntityOptions(this.entity);
        }
      }
    },
    'event_dates': {
      handler() {
        this.$nextTick(async () => {
          await Promise.all(this.event_dates.forEach(async (item, index) => {
            this.dates_is_valid = true;
            const observer = this.$refs[`observer_${index}`];

            if (observer[0]) {
              const result = await observer[0].validate()

              this.dates_is_valid = this.dates_is_valid && result;
            }
          }));
        })
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss" scoped>
.input-file {
  opacity: 0;
  height: 0;
  width: 0%;
  position: relative;
}

.input-style {
  color:#d4ecff;
}

.text-overflow {
  text-align: center;
  overflow: hidden;
  max-width: 135px;
  text-overflow: ellipsis;
}
</style>
