<template>
  <v-form>
    <v-text-field
        v-model="form.first_name"
        :error-messages="errors.first_name"
        label="Имя"
        name="first_name"
        required
    ></v-text-field>
    <v-text-field
        v-model="form.last_name"
        :error-messages="errors.last_name"
        label="Фамилия"
        name="last_name"
    ></v-text-field>
    <v-text-field
        v-model="form.slug"
        :error-messages="errors.slug"
        label="Slug"
        name="slug"
    ></v-text-field>
    <v-text-field
        v-model="form.patronymic"
        :error-messages="errors.patronymic"
        label="Отчество"
        name="patronymic"
    ></v-text-field>
    <v-textarea
        v-model="form.post"
        :error-messages="errors.post"
        label="Должность"
        name="post"
    ></v-textarea>

    <v-text-field
        v-model="form.phone"
        :error-messages="errors.phone"
        label="Телефон"
        prepend-icon="mdi-phone-classic"
        hint="К примеру, +7(926)000-00-00"
        name="phone"
    ></v-text-field>

    <v-text-field
        v-model="form.personal_phone"
        :error-messages="errors.personal_phone"
        label="Персональный телефон"
        prepend-icon="mdi-phone-classic"
        hint="К примеру, +7(926)000-00-00"
        name="personal_phone"
    ></v-text-field>

    <v-text-field
        v-model="form.email"
        prepend-icon="mdi-at"
        :rules="[rules.email]"
        :error-messages="errors.email"
        label="Email"
        name="email"
    ></v-text-field>

    <v-text-field
        v-model="form.telegram"
        prepend-icon="mdi-at"
        :error-messages="errors.telegram"
        label="Telegram"
        name="telegram"
    ></v-text-field>

    <v-text-field
        v-model="form.work_hours"
        :error-messages="errors.work_hours"
        label="Часы работы"
        name="work_hours"
    ></v-text-field>

    <v-text-field
        v-model="form.popularity_ratio"
        :error-messages="errors.popularity_ratio"
        label="Рейтинг"
        name="popularity_ratio"
        type="number"
        :rules="[rules.rating]"
        :max="10"
        :min="0"
    ></v-text-field>

    <v-text-field
        v-model="form.video_url"
        :error-messages="errors.video_url"
        label="Ссылка на видео"
        name="video_url"
    ></v-text-field>


    <label>Краткая биография</label>
    <tip-tap v-model="form.short_description" />
    <v-checkbox v-model="form.is_contact_person" label="Контактное лицо" />
    <label>Биография</label>
    <tip-tap v-model="form.biography" />
    <v-autocomplete
        v-model="form.selected_partners"
        prepend-icon="mdi-account"
        v-if="persons && form.id"
        :items="persons"
        item-text="title"
        item-value="id"
        label="Выберите партнеров"
        multiple
    >
      <template v-slot:selection="data">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removePartners(data.item)"
        >
          <v-avatar left>
            <v-img :src="data.item.image" />
          </v-avatar>
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <v-list-item-avatar>
          <img :src="data.item.image">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.title }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <media-widget
        class="mb-1"
        label="Изображение персоны"
        :medias="form.image"
        :is-multiple="false"
        @onChange="setPreviewImage"
    />
    <media-widget
        class="mb-1"
        label="Анимированое изображение"
        :medias="form.anim_image"
        :is-multiple="false"
        @onChange="setAnimImage"
    />
    <media-uploader
        :loaded-medias="person.loaded_medias"
        @completeUploads="onCompleteUploads"
    />
    <v-checkbox
        class="mb-1"
        v-model="form.is_adult_explicit_content"
        label="18+"
    />
    <v-textarea
        class="mb-1"
        v-model="form.text_of_a_foreign_agent"
        label="Текст для иностранного агента"
    />
    <v-card-actions class="pa-5 text-left">
      <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(true)"
      >
        Сохранить
      </v-btn>
      <v-btn
          color="primary"
          :disabled="isSaveDisabled"
          :loading="isSaveDisabled"
          @click="submit(false)"
      >
        Сохранить и продолжить
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import TipTap from "../editor/TipTap";
import axiosInstance from "@/axiosInstance";

export default {
  name: "PersonForm",
  components: {
    MediaUploader,
    MediaWidget,
    TipTap,
  },
  props: {
    person: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      id: null,
      first_name: null,
      last_name: null,
      slug: null,
      patronymic: null,
      post: null,
      image: null,
      anim_image: null,
      biography: null,
      video_url: null,
      personal_phone: null,
      email: null,
      phone: null,
      short_description: null,
      popularity_ratio: null,
      selected_partners: null,
      is_contact_person: false,
      is_adult_explicit_content: false,
      text_of_a_foreign_agent: null,
      telegram: null,
      work_hours: null,
    },
    persons: [],
    mediaIds: [],
    isSaveDisabled: false,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Введите правильный email адрес'
      },
      rating: value => (value <= 10 && value > 0) || 'Число должно быть в диапазоне от 0 до 10',
    },
  }),
  created() {
    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }
    this.getPersons();
  },
  methods: {
    submit(isNeedRedirect) {
      this.isSaveDisabled = true;
      this.$emit('onSubmit', { form: this.form, mediaIds: this.mediaIds, isNeedRedirect: isNeedRedirect, });
    },
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    setPreviewImage(image) {
      this.form.image = image;
    },
    setAnimImage(image) {
      this.form.anim_image = image;
    },
    removePartners(item) {
      const index = this.form.selected_partners.indexOf(item.id);
      if (index >= 0) this.form.selected_partners.splice(index, 1);
    },
    async getPersons() {
      await axiosInstance.get('partners/select-list').then((response) => {
        this.persons = response.data.data;
      });
    },
  },
  watch: {
    person: {
      handler(person) {
        if (person && Object.keys(person).length > 0) {
          this.form = person;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
